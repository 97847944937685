import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from 'components/Button/';

import { package as PackagePropType } from 'lib/CustomPropTypes';
import { useGeoLocation, useTracking } from 'lib/Hooks';
import { Picture } from 'components/Picture';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import { getAuthenticationState, launchAuthenticationModal } from 'lib/authenticationUtils';

import { INITIALIZED, UNAUTHENTICATED } from 'lib/myNewsConstants';
import styles from './styles.module.scss';

/**
 * Creates PromoBanner package. Intended to prompt users to sign up for an account.
 * @param {object} props package data
 * @param {PackagePropType} props.content package data
 * @param {string} props.pkgClassName Additional classnames to provide to the package
 * @returns {React.ReactElement} - The rendered PromoBanner component
 */
const PromoBanner = ({
  content,
  pkgClassName = 'pkg accountPromo',
}) => {
  const authenticationState = getAuthenticationState();
  const authenticationModal = launchAuthenticationModal();
  const { isUsa } = useGeoLocation();
  const track = useTracking('hfs_identity_packageSignupClick');

  const isGeneral = content?.subType === 'general';

  const shouldShowPackage = isGeneral ? true : (authenticationState === INITIALIZED
    || authenticationState === UNAUTHENTICATED) && isUsa;

  if (!shouldShowPackage) return null;

  const {
    metadata: {
      promoImage, text, title, buttonUrl, disclaimerText, logo, id: packageId,
    },
  } = content;

  const classes = classNames(
    pkgClassName,
    styles.section,
  );

  let button;

  if (isGeneral) {
    button = (
      <Button
        title={text}
        tiltClasses={styles.button}
        url={buttonUrl}
      />
    );
  } else {
    button = (
      <Button
        onClick={() => {
          track();
          authenticationModal();
        }}
        title={text}
        tiltClasses={styles.button}
      />
    );
  }

  return (
    <section
      className={classNames(classes)}
      data-testid="account-promo"
      data-activity-map="account-promo-package"
      data-packageid={packageId}
    >
      <div
        className={
          classNames(
            styles.container,
            'grid-col-push-1-m grid-col-10-m',
          )
        }
        data-testid="container"
      >
        <div className={styles.left}>
          {isGeneral && logo
            ? (
              <img
                className={styles.logo}
                src={logo}
                alt="Promo Banner Logo"
              />
            ) : null }
          <h2 className={styles.title}>{title}</h2>
          {button}
          {isGeneral
            ? (
              <p className={styles.disclaimer}>
                {disclaimerText}
              </p>
            ) : null }
        </div>
        <div className={styles.right}>
          <Picture
            url={promoImage}
            alt="Promo Package"
            className="promoPackagePicture"
            flavor="fit"
            lazyloaded
            placeholder
            responsiveFlavors={{
              s: AIMS_FLAVORS.FIT_760w,
              m: AIMS_FLAVORS.FIT_660w,
              l: AIMS_FLAVORS.FIT_760w,
              xl: AIMS_FLAVORS.FIT_1000w,
            }}
          />
        </div>
      </div>
    </section>
  );
};

PromoBanner.propTypes = {
  content: PackagePropType.isRequired,
  pkgClassName: PropTypes.string,
};


export { PromoBanner };
